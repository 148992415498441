@import "./../../styles/base/variable";

:local(.projectGalleryItemStyles) {
  padding: 2rem;
}

:local(.projectGalleryItemImgStyles) {
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.42) 8px 11px 0px ;
  border: 0.5rem solid #000;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.22) 20px 19px 20px;
    transform: translate3d(0px, -1px, 0px);
  }
}

:local(.projectGalleryItemTextContainerStyles) {
  margin-top: 1rem;
  padding-left: 1rem;
  z-index: 99;
}

:local(.projectGalleryItemTitleStyles) {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;

  a {
    text-decoration: none;
    color: $plain-white;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;

    &:hover {
      color: $super-strong;
    }
  }
}

:local(.projectGalleryDescription) {
  color: $plain-white;
}

:local(.projectGalleryContainer){
  margin-top: 3rem;
}
