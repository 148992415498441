@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;600;700');

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  outline: none !important;
  font-family: 'IBM Plex Mono', monospace;
  letter-spacing: 0.001rem;
}

*:focus {
  outline: none !important;
}
