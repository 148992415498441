@import '../../styles/base/variable';

:local(.menuStyles) {
  display: block;
  height: 0;


  @media all and (max-width: 768px) {
    position: fixed;
    width: 100vw;
    background-color: #000;
    opacity: 0.9;
    z-index: 99;
    height: 0;

    &:local(.openMenu) {
      height: 100vh;
      display: block;
    }
  
    &:local(.closeMenu) {
      height: 0;
      display: none;
    }
  }


}

:local(.menuItemContainerStyles) {
  @media all and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
}

:local(.menuItemAreaStyles) {
  display:flex;
  justify-content: flex-end;
  padding-top: 1.5rem;

  @media all and (max-width: 768px) {
    display:initial;
    justify-content: initial;
    padding-top: initial;
    text-align: center;
  }
}

:local(.menuItemStyles) {
  a {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    transition: 0.3s;
    margin-right: 2rem;

    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;

    &:hover {
      color: $super-strong;
      cursor: pointer;
    }
  }

  @media all and (max-width: 768px) {
    a {
      font-size: 3rem;
      font-weight: 700;
      margin-right: initial;

      &:hover {
        color: #ffce3b;
      }
    }
  }
}
