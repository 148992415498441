@import '../../styles/base/variable';

:local(.projectStyles) {
  padding: 6rem 2rem 4rem 2rem;
  background-color: $purple-kinda;
  min-height: 100%;
}

:local(.projectImageContainerStyles) {
  width: 100%;
}

:local(.projectImageStyles) {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  text-align: center;
  width: 80%;
  border-radius: 0.5rem;
  border: 0.5rem solid #000;
}

:local(.background){
  height: 100vh;
}

:local(.markdownContent){
  color: $plain-white;
}