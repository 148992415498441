:local(#firstChild) {
  margin-left: 0.5rem;

  @media all and (max-width: 768px) {
    margin-left: 0.2rem;
  }
}

:local(.homeLinkContainerStyles) {
  & > span {
    margin-left: 1rem;
    margin-right: 1rem;

    a {
      color: #fff;
      text-decoration: none;
      transition: 0.2s;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;

      &:hover {
        color: rgb(88, 88, 88);
      }
    }
  }
}
