:local(.navBarContainerStyles) {
  display: none;
  @media all and (max-width: 768px) {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 100;
  }
}

:local(.navBarHamburgerStyles) {
  @media all and (max-width: 768px) {
    outline: none;
  }
}
