@import '../../styles/base/variable.scss';

:local(.markdownStyles) {
  color: $plain-white;
  a {
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;

    &:hover {
      color: $super-strong;
    }
  }

  li {
    margin-bottom: 0.5rem;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    border-radius: 0.5rem;
    box-shadow: 0 2px 50px rgba(0, 0, 0, 0.1);

    &[alt=profilePicture] {
      border-radius: initial;
      box-shadow: initial;
      height: 441px;
      width: 595px;
    }

    @media all and (max-width: 992px) {
      &[alt=profilePicture] {
        width: 90%;
        height: initial;
      }
    }
  }

  p code {
    color: #d0d0d0;
    border: 1px solid black;
    background-color: #000;
  }
}
