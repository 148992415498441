@import '../../styles/base/variable';

:local(.projectLinksStyles) {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

:local(.projectLinksItemStyles) {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  a {
    font-weight: 600;
    text-decoration: none;
    color: $plain-white;
    background-color: #8833e6;
    border-color: #8833e6;
    box-shadow: 6px 7px 0px 0px rgba(0, 0, 0, 0.35) ;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    

    &:hover {
      background-color: #573180;
      border-color: #573180;
      color: #d4d4d4;
    }
  }
}
