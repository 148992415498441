:local(.background) {
  height: 100vh;
  color: #FFF;
  background: linear-gradient(-45deg,  #fad0c4, #ff9a9e, #a1c4fd, #72b1ce);
  background-size: 800% 800%;
  -webkit-animation: gradient 20s ease infinite;
  -moz-animation: gradient 20s ease infinite;
  animation: gradient 20s ease infinite;

  @-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }

  @-moz-keyframes gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
}

:local(.container) {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 15rem;

  @media all and (max-width: 768px) {
    margin-left: 2rem;
  }

  @media all and (min-width:769px) and (max-width: 1200px) {
    margin-left: 7rem;
  }
}

:local(.description) {
  width: 30vw;
  margin-bottom: 2rem;
  margin-left: 0.5rem;

  a {
    text-decoration: none;
    color: #FFF;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;

    &:hover {
      color: rgb(88, 88, 88);
    }
  }

  @media all and (max-width: 768px) {
    width: auto;
    margin-right: 2rem;
    margin-bottom: 1.5rem;
    margin-left: 0.1rem;
  }
}
