:local(.pageHeaderStyles) {
  text-align: center;
}

:local(.pageHeaderTitleStyles) {
  color: #fff;
  font-weight: 700;
  font-size: 3rem;
}

:local(.pageHeaderSubtitleStyles) {
  color: #fff;
}
