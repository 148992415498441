@import '../../styles/base/variable.scss';

:local(.contactLinksItemStyles) {
  text-align: center;
  margin-bottom: 3rem;

  &:first-child {
    margin-top: 3rem;
  }

  &:last-child {
    margin-bottom: 2rem;
  }
}

:local(.contactLinksItemTitleStyles) {
  font-weight: 600;
  font-size: 2rem;

  a {
    cursor: pointer;
    text-decoration: none;
    color: #2f2f2f;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;

    &:hover {
      color: $super-strong;
    }
  }
}

:local(.contactLinksItemDescriptionStyles) {
  margin-bottom: 0.25rem;
}

:local(.contactLinksItemLinkStyles) {
  font-weight: 600;

  a {
    text-decoration: none;
    color: #FFF;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;

    &:hover {
      color: $super-strong;
    }
  }
}

:local(.contactLinksItems) {
  -webkit-animation:  slideInDown 1s ease 1;
  -moz-animation: slideInDown 1s ease 1;
  animation:  slideInDown 1s ease 1;

  @-webkit-keyframes slideInDown {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-moz-keyframes slideInDown {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideInDown {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
