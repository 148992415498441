:local(.aboutStyles) {
  padding: 6rem 2rem 4rem 2rem;
  color: #fff;
  background-color: #bd5555;
  min-height: 100%;

}

:local(.background) {
  height:100vh;
}
